<template>
<div>
  <b-card class="card-custom gutter-b">
    <template #header>
      <b-row class="w-100 d-flex align-items-center" no-gutters>
        <b-col xs=6>
          <h5 class="m-0">Journal</h5>
        </b-col>
        <b-col xs=6 class="text-right">
          <b-button variant="primary" v-b-modal.cm-modal-form>Add New</b-button>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col sm=12 class="mb-5">
        <b-row no-gutters class="justify-content-between">
          <b-col sm=12 md=3 class="mb-3 mb-sm-0">
            <b-input-group prepend="Search">
              <b-form-input v-model="paging.search" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!paging.search" @click="paging.search = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm=12 md=2 class="d-flex justify-content-end">
            <b-input-group prepend="Per Page">
              <b-select :options="opt.perPage" v-model="paging.perPage" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm=12 class="mb-5">
        <b-overlay :show="table.loading">
          <b-table show-empty small :fields="table.fields" :items="table.items" :current-page="paging.page" :sort-by.sync="paging.sortBy" :sort-desc.sync="paging.sortDesc" :sort-direction="paging.sortDirection" bordered striped hover responsive>
            <template #cell(no)="row">
              {{ (paging.perPage * (paging.page-1)) + row.index + 1 }}
            </template>
            <template #cell(parent_title)="row">
              {{ (row.item.id_parent) ? row.item.parent_title : 'Main' }}
            </template>
            <template #cell(action)="row">
              <b-button size="sm" variant="danger" @click="doDelete(row.item.id,false)" title="Delete">
                <b-icon icon="trash" />
              </b-button>
              <b-button size="sm" variant="info" @click="doUpdate(row.item.id)" class="ml-0 ml-sm-3 mt-2 mt-sm-0" title="Update">
                <b-icon icon="pencil" />
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col sm=12>
        <b-row no-gutters class="w-100">
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
            Showing {{paging.perPage}} data out of {{table.totalRows}}
          </b-col>
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination v-model="paging.page" :total-rows="table.totalRows" :per-page="paging.perPage"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
  <b-modal title="Journal Form" id="cm-modal-form" hide-footer size="lg" centered>
    <form @submit.prevent="doSave()">
      <b-row>
        <b-col sm=12>
          <b-form-group label="Featured Image" description="Click to change/ add Image" class="project-img">
            <label for="cm_form_img" class="w-100" ref="previewImage">
              <b-img-lazy :src="(form.data.image) ? form.data.image : require('@/assets/images/default.png')" fluid class="w-100"/>
              <input type="file" ref="cm_form_img" hidden id="cm_form_img"
              @change="previewImage($event,'MAIN')" accept="image/*"> 
            </label>
          </b-form-group>
          <b-form-group label="Other Image">
            <input type="file" ref="cm_form_img_other" multiple hidden id="cm_form_img_other"
              @change="previewImage($event,'OTHER')" accept="image/*"> 
            <b-row>
              <b-col sm=6 md=3 v-for="(tImg,tImgIndex) in form.data.child.image" :key="`tImg-${tImgIndex}`" v-show="!tImg.deleted">
                <span class="cm-delete-img" @click="deleteImage(tImgIndex)">+</span>
                <b-img :src="tImg.image" style="height:120px;object-fit:contain" fluid class="w-100 mb-3" thumbnail/>
              </b-col>
              <b-col sm=6 md=3>
                <label for="cm_form_img_other" class="btn btn-success btn-block m-0 d-flex justify-content-center align-items-center " style="height:120px;font-size:1000%">+</label>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col sm=12>
          <b-form-group label="Title">
            <b-input v-model="form.data.title" />
          </b-form-group>
          <b-form-group label="Content">
            <wysiwyg v-model="form.data.content" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex py-3 border-top justify-content-end">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-form')">Close</b-button>
        <b-button type="submit" :disabled="form.loading" variant="primary" class="ml-3">
          <b-spinner small v-show="form.loading" /> Save
        </b-button>
      </div>
    </form>
  </b-modal>
  <b-modal header-bg-variant="warning" centered body-bg-variant="warning" id="cm-modal-delete" size="sm" hide-footer hide-header>
    <div class="text-center">
      <h1 class="text-light">Warning</h1>
      <p class="text-center mb-3 text-light">Are You Sure to Delete this Data ?</p>
      <div class="d-flex justify-content-center">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-delete')">Cancel</b-button>
        <b-button variant="danger" @click="doDelete(form.deleteId,true)" class="ml-3">Delete!!!</b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
export default {
  data() {
    return {
      form: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'JOURNAL ITEM',
          child : {
            image : []
          }
        },
        deleteId: null
      },
      table: {
        items: [],
        fields: [{
            key: 'no',
            label: 'No',
            sortable: false,
            class: 'w-5 text-center'
          },
          {
            key: 'title',
            label: 'Title',
            sortable: true,
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
            class: 'text-center w-10'
          }
        ],
        totalRows: 0,
        loading: false
      },
      paging: {
        page: 1,
        perPage: 10,
        search: '',
        sortBy: 'title',
        sortDesc: false,
        type: 'JOURNAL ITEM'
      },
      opt: {
        perPage: [10, 20, 30, 40, 50, 100, 200],
        parent: [
          {
            text : 'Main',
            value : null
          }
        ]
      },
      showPass: false
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      data: {
        title: {
          required
        },
        type: {
          required
        }
      }
    }
  },
  methods: {
    doLoad() {
      this.table.loading = true
      let payload = this.paging
      payload.sortDirection = this.paging.sortDesc ? 'asc' : 'desc'
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            this.table.items = res.data
            this.table.totalRows = res.total
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.table.loading = false
        })
        .catch(e => {
          this.table.loading = false
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doUpdate(id) {
      let url = baseUrl + `/api/post/get`
      let data = {
        id: id
      }
      this.fetchData(url, 'POST', data)
        .then(res => {
          let resData = res.data
          this.form.data.id =  resData.id
          this.form.data.image =  resData.image
          this.form.data.title =  resData.title
          this.form.data.content =  resData.content
          this.form.data.id_parent =  resData.id_parent
          this.form.data.type =  'JOURNAL ITEM'
          resData.child.map((x)=>{
            switch (x.type) {
              case 'JOURNAL ITEM IMAGES':
                x.deleted = false
                this.form.data.child.image.push(x)
              break;
            }
          })
          this.$bvModal.show('cm-modal-form')
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'Get:Internal server error', 'danger')
        });
    },
    doSave(){
      this.form.loading = true
      this.$v.form.data.$touch()
      if (this.$v.form.data.$invalid) {
        this.form.loading = false
        this.notif('Warning', 'Please complete required field', 'warning')
      } else {
        let url = baseUrl+'/api/post/create'
        if(this.form.data.id)
          url = baseUrl+'/api/post/update'
        this.fetchData(url,'POST',this.form.data)
        .then(res => {
          if (res.status) {
            this.notif('Success', res.msg, 'success')
            this.clearForm()
            this.doLoad()
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.form.loading = false
        })
        .catch(e => {
          this.form.loading = false
          this.notif('Failed', 'Save:Internal server error', 'danger')
        });
      }
    },
    doDelete(id, promt) {
      if (promt) {
        let url = baseUrl + `/api/post/delete`
        let data = {
          id: id
        }
        this.fetchData(url, 'POST', data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoad()
              this.$bvModal.hide('cm-modal-delete')
            } else {
              this.notif('Error', res.msg, 'danger')
              this.doLoad()
              this.$bvModal.hide('cm-modal-delete')
            }
          })
          .catch(e => {
            console.log(e)
            this.notif('Failed', 'Delete:Internal server error', 'danger')
          });
      } else {
        this.form.deleteId = id
        this.$bvModal.show('cm-modal-delete')
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    previewImage(event,target) {
      let theImg = null
      let vm = this
      const inp = this.$refs.cm_form_img
      const inpOther = this.$refs.cm_form_img_other
      if(target=='MAIN'){
        theImg = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(theImg);
        reader.onload = function () {
          vm.form.data.image = reader.result
          inp.type = 'text';
          inp.type = 'file';
        };
        reader.onerror = function () {
          vm.form.data.image = null
          inp.type = 'text';
          inp.type = 'file';
        };
      } else {
        theImg = event.target.files
        for(let x=0;x<theImg.length;x++){
          let reader = new FileReader();
          reader.readAsDataURL(theImg[x]);
          reader.onload = function () {
            let pImg = {
              id: null,
              image: reader.result,
              title: 'JOURNAL ITEM IMAGES',
              content: null,
              id_parent: null,
              type: 'JOURNAL ITEM IMAGES',
              deleted : false,
            }
            vm.form.data.child.image.push(pImg)
            inpOther.type = 'text';
            inpOther.type = 'file';
          };
          reader.onerror = function (e) {
            console.log(e)
            inpOther.type = 'text';
            inpOther.type = 'file';
          };
        }
      }
    },
    deleteImage(idx){
      let id = this.form.data.child.image[idx].id
      if(id > 0){
        this.form.data.child.image[idx].deleted = true
      } else {
        this.form.data.child.image.splice(idx, 1);
      }
    },
    clearForm() {
      this.$v.form.data.$reset()
      this.form.data = {
        id: null,
        image: null,
        title: null,
        content: null,
        id_parent: null,
        type: 'JOURNAL ITEM',
        child : {
          image : []
        }
      }
      this.form.deleteId = null
      this.$bvModal.hide('cm-modal-form')
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
    thousand(num) {
      if (num) {
        let num_parts = num.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
      } else {
        return 0
      }
    },
  },
  watch: {
    paging: {
      handler() {
        this.doLoad()
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Journal"
    }])
    this.doLoad()
    this.$root.$on('bv::modal::hide', () => {
      this.clearForm()
    })
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
